import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Header from './Header';
import UserIcon from './UserIcon';

type TacoCardProps = {
  content: string,
  userId: number,
  userName: string,
  userIconUrl: string,
};

const TacoCard: React.VFC<TacoCardProps> = ({
  content,
  userId,
  userName,
  userIconUrl,
}) => (
  <Row className="border">
    <Col xs={1} style={{ paddingTop: '10px' }}>
      <UserIcon url={userIconUrl} sizeBasePx={40} />
    </Col>
    <Col xs={11} style={{ padding: '10px', overflowWrap: 'break-word' }}>
      <Header text={`${userName} @${userId}`} lv={6} />
      {content}
    </Col>
  </Row>
);

export default TacoCard;
