import React from 'react';
import CycleArrow from './CycleArrow';
import DotsCircled from './DotsCircled';
import Bookmark from './Bookmark';
import Comment from './Comment';
import Dots from './Dots';
import Home from './Home';
import Hash from './Hash';
import List from './List';
import Message from './Message';
import Notification from './Notification';
import Person from './Person';
import Search from './Search';
import Share from './Share';
import Hart from './Hart';
import Taco from './Taco';

type IconProps = {
  kind: 'Bookmark' | 'Home' | 'Comment' | 'CycleArrow' | 'Dots' | 'DotsCircled' | 'Hart' | 'Hash' | 'List' | 'Message' | 'Notification' | 'Person' | 'Search' | 'Share' | 'Taco';
};

const Icon: React.VFC<IconProps> = ({ kind }) => {
  switch (kind) {
    case 'CycleArrow':
      return <CycleArrow />;
    case 'DotsCircled':
      return <DotsCircled />;
    case 'Bookmark':
      return <Bookmark />;
    case 'Comment':
      return <Comment />;
    case 'Dots':
      return <Dots />;
    case 'Home':
      return <Home />;
    case 'Hash':
      return <Hash />;
    case 'List':
      return <List />;
    case 'Message':
      return <Message />;
    case 'Notification':
      return <Notification />;
    case 'Person':
      return <Person />;
    case 'Search':
      return <Search />;
    case 'Share':
      return <Share />;
    case 'Hart':
      return <Hart />;
    default:
      return <Taco />;
  }
};

export default Icon;
