import React from 'react';
import { Form, Row } from 'react-bootstrap';

const SearchBox: React.VFC = () => (
  <Row className="p-1" xs={12}>
    <Form>
      <Form.Control type="text" placeholder="Search Taco" />
    </Form>
  </Row>
);

export default SearchBox;
