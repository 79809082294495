import React from 'react';

const DotsCircled: React.VFC = () => (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5 0.916748C7.45002 0.916748 0.916687 7.45008 0.916687 15.5001C0.916687 23.5501 7.45002 30.0834 15.5 30.0834C23.55 30.0834 30.0834 23.5501 30.0834 15.5001C30.0834 7.45008 23.55 0.916748 15.5 0.916748ZM15.5 27.1668C9.05419 27.1668 3.83335 21.9459 3.83335 15.5001C3.83335 9.05425 9.05419 3.83342 15.5 3.83342C21.9459 3.83342 27.1667 9.05425 27.1667 15.5001C27.1667 21.9459 21.9459 27.1668 15.5 27.1668Z" fill="#333333" />
    <path d="M8.20837 17.6875C9.4165 17.6875 10.3959 16.7081 10.3959 15.5C10.3959 14.2919 9.4165 13.3125 8.20837 13.3125C7.00025 13.3125 6.02087 14.2919 6.02087 15.5C6.02087 16.7081 7.00025 17.6875 8.20837 17.6875Z" fill="black" />
    <path d="M15.5 17.6875C16.7081 17.6875 17.6875 16.7081 17.6875 15.5C17.6875 14.2919 16.7081 13.3125 15.5 13.3125C14.2919 13.3125 13.3125 14.2919 13.3125 15.5C13.3125 16.7081 14.2919 17.6875 15.5 17.6875Z" fill="black" />
    <path d="M22.7917 17.6875C23.9998 17.6875 24.9792 16.7081 24.9792 15.5C24.9792 14.2919 23.9998 13.3125 22.7917 13.3125C21.5836 13.3125 20.6042 14.2919 20.6042 15.5C20.6042 16.7081 21.5836 17.6875 22.7917 17.6875Z" fill="black" />
  </svg>
);

export default DotsCircled;
