import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row } from 'react-bootstrap';
import Home from './pages/Home';
import LeftSideMenu from './components/LeftSideMenu';
import RightSideMenu from './components/RightSideMenu';

const App: React.VFC = () => (
  <Container>
    <Row>
      <LeftSideMenu />
      <Home />
      <RightSideMenu />
    </Row>
  </Container>
);

export default App;
