import React from 'react';
import { Col } from 'react-bootstrap';

type UserIconProps = {
  url: string;
  sizeBasePx: number;
};

const UserIcon: React.VFC<UserIconProps> = ({ url, sizeBasePx }) => {
  const style = {
    width: sizeBasePx,
    height: sizeBasePx,
    borderRadius: '50%',
  };
  return (
    <Col style={style}>
      <img src={url} alt={url} style={style} />
    </Col>
  );
};

export default UserIcon;
