import React from 'react';
import { Col } from 'react-bootstrap';
import Icon from '../icons/Icon';
import MenuItem from './MenuItem';

const LeftSideMenu: React.VFC = () => (
  <Col
    xs={3}
    className="bg-light border p-2"
    style={{ position: 'sticky', top: '25px' }}
  >
    <Col className="p-3">
      <Icon kind="Taco" />
    </Col>
    <MenuItem kind="Home" />
    <MenuItem kind="Hash" />
    <MenuItem kind="Notification" />
    <MenuItem kind="Message" />
    <MenuItem kind="Bookmark" />
    <MenuItem kind="List" />
    <MenuItem kind="Person" />
    <MenuItem kind="DotsCircled" />
  </Col>
);

export default LeftSideMenu;
