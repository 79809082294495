import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Icon from '../icons/Icon';

type MenuItemProps = {
  kind: 'Bookmark' | 'Home' | 'Comment' | 'CycleArrow' | 'Dots' | 'DotsCircled' | 'Hart' | 'Hash' | 'List' | 'Message' | 'Notification' | 'Person' | 'Search' | 'Share';
};

const MenuItem: React.VFC<MenuItemProps> = ({ kind }) => (
  <Col className="p-1" xs={12}>
    <Button variant="light" className="" style={{ textAlign: 'left', width: '100%' }}>
      <Row>
        <Col xs={2} style={{ textAlign: 'center' }}>
          <Icon kind={kind} />
        </Col>
        <Col xs={10}>
          {kind}
        </Col>
      </Row>
    </Button>
  </Col>
);

export default MenuItem;
