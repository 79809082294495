import React from 'react';
import { Row } from 'react-bootstrap';

type HeaderProps = {
  text: string,
  lv: 1 | 2 | 3 | 4 | 5 | 6,
  style?: React.CSSProperties,
};

const ChangeHeaderLv: React.VFC<HeaderProps> = ({ text, lv, style }) => {
  switch (lv) {
    case 1:
      return <h1 style={style}>{text}</h1>;
    case 2:
      return <h2 style={style}>{text}</h2>;
    case 3:
      return <h3 style={style}>{text}</h3>;
    case 4:
      return <h4 style={style}>{text}</h4>;
    case 5:
      return <h5 style={style}>{text}</h5>;
    default:
  }
  return <h6 style={style}>{text}</h6>;
};

const Header: React.VFC<HeaderProps> = ({ text, lv, style = { } }) => (
  <Row>
    <ChangeHeaderLv text={text} lv={lv} style={style} />
  </Row>
);

export default Header;
