import React from 'react';
import { Col } from 'react-bootstrap';
import SearchBox from './SearchBox';

const RightSideMenu: React.VFC = () => (
  <Col xs={3} className="bg-light border-start border-end">
    <SearchBox />
  </Col>
);

export default RightSideMenu;
