import React from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import UserIcon from '../../components/UserIcon';

const TacoForm: React.VFC = () => {
  const ref = React.useRef<HTMLTextAreaElement>(null);
  const taco = (): void => {
    fetch('http://localhost:3000/tweet', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: 1,
        content: ref.current?.value,
      }),
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <Row
        style={{
          paddingBottom: '10px',
        }}
      >
        <Col xs={1}>
          <UserIcon
            url="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"
            sizeBasePx={45}
          />
        </Col>
        <Col xs={9}>
          <Form.Control
            ref={ref}
            as="textarea"
            rows={1}
            placeholder="What's happening?"
            style={{
              resize: 'none',
            }}
          />
        </Col>
        <Col style={{ textAlign: 'end' }}>
          <Button
            variant="primary"
            onClick={taco}
          >
            Tacoる
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TacoForm;
