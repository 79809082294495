import React from 'react';
import { Col } from 'react-bootstrap';
import Async from 'react-async';
import Header from '../components/Header';
import TacoCard from '../components/TacoCard';
import TacoForm from './home/TacoForm';

const getTacos = async (): Promise<any> => {
  const res = await fetch('http://localhost:3000/tweets');
  const tweets = await res.json();
  console.log(tweets);
  return tweets;
};

const Home: React.VFC = () => (
  <Async promiseFn={getTacos}>
    <Col xs={6} className="bg-light" style={{ height: '100%' }}>
      <Header text="Home" lv={4} style={{ padding: '10px' }} />
      <TacoForm />
      <Async.Pending>Loading...</Async.Pending>
      <Async.Rejected>
        {(error) => JSON.stringify(error.message)}
      </Async.Rejected>
      <Async.Fulfilled>
        {(tweets: { id: number, content: string }[]) => {
          console.log(tweets);
          return tweets.map((tweet) => (
            <TacoCard
              content={tweet.content}
              userId={tweet.id}
              userIconUrl="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"
              userName="eraser"
            />
          ));
        }}
      </Async.Fulfilled>
    </Col>
  </Async>
);

export default Home;
